<template>
    <!--
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')

  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          teal: colors.teal,
          cyan: colors.cyan,
        }
      }
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ]
  }
  ```
-->
    <div class="sm:mt-16 lg:mt-0 ">
        <div class="relative">
            <main>
                <div class="rounded-3xl mt-10 mx-10 pt-10  bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 overflow-hidden">
                    <div class="mx-auto max-w-7xl lg:px-8">
                        <div class="lg:grid lg:grid-cols-2 lg:gap-8">
                            <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                                <div class="lg:py-24">
<!--                                    <a href="#" class="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200">-->
<!--                                        <span class="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-gradient-to-r from-teal-500 to-cyan-600 rounded-full">We're hiring</span>-->
<!--                                        <span class="ml-4 text-sm">Visit our careers page</span>-->
<!--                                        &lt;!&ndash; Heroicon name: solid/chevron-right &ndash;&gt;-->
<!--                                        <svg class="ml-2 w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--                                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />-->
<!--                                        </svg>-->
<!--                                    </a>-->
                                    <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                        <span class="block">Jiruto is a family (company)</span>
                                        <span class="bg-clip-text text-transparent bg-gradient-to-r from-teal-200 to-cyan-400 block">We're a community :)</span>
                                    </h1>
                                    <p class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                        Hi, I'm Yarden, and I'm the person behind Jiruto. A husband, a father of twins, and a self learner.
                                    </p>
                                    <p class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                        Jiruto was born from my efforts learning things online, and although I'm the one coding and bootstrapping it, Jiruto is a team effort.
                                    </p>
                                    <p class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                         Thanks to Aviv, my smart and wonderful wife, and the family & friends around me, I have people to brainstorm, QA and validate.
                                    </p>
                                    <p class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                        I'm trying to train my newborns to code, so maybe I'll have help with the heavy lifting "soon".
                                    </p>
                                    <p class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                        Welcome to Jiruto. We really hope you can benefit from our efforts.
                                    </p>
                                </div>
                            </div>
                            <div class="mt-5 sm:-mb-48 lg:m-0 lg:relative">
                                <div class="mx-auto max-w-md px-4 sm:max-w-2xl overflow-hidden sm:px-6 lg:max-w-none lg:px-0 ">
                                    <!-- Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ -->
                                    <img class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="https://tailwindui.com/img/component-images/cloud-illustration-teal-cyan.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Feature section with grid -->
                <div class="rounded-3xl mt-10 mx-10 relative bg-purple-100 py-16 sm:py-24 lg:py-32">
                    <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                        <h2 class="text-base font-semibold tracking-wider text-cyan-600 uppercase">But, But...</h2>
                        <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                            Why are you doing this? Why education?
                        </p>
                        <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                            Because it opens so many doors. Almost any dream you might have, will require you to learn something. I want to help people reach their dreams.
                        </p>
                        <div class="mt-12 flex justify-center">
                            <img class="object-contain max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FMoonCatLearningNoBackgroundSmaller.png?alt=media&token=9eb6526c-0181-498f-bc43-80b9e0ee80fa" alt="">


<!--                            <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">-->
<!--                                <div class="pt-6">-->
<!--                                    <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">-->
<!--                                        <div class="-mt-6">-->
<!--                                            <div>-->
<!--                      <span class="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg">-->
<!--                        &lt;!&ndash; Heroicon name: outline/cloud-upload &ndash;&gt;-->
<!--                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">-->
<!--                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />-->
<!--                        </svg>-->
<!--                      </span>-->
<!--                                            </div>-->
<!--                                            <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">Push to Deploy</h3>-->
<!--                                            <p class="mt-5 text-base text-gray-500">-->
<!--                                                Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.-->
<!--                                            </p>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->

<!--                                <div class="pt-6">-->
<!--                                    <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">-->
<!--                                        <div class="-mt-6">-->
<!--                                            <div>-->
<!--                      <span class="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg">-->
<!--                        &lt;!&ndash; Heroicon name: outline/lock-closed &ndash;&gt;-->
<!--                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">-->
<!--                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />-->
<!--                        </svg>-->
<!--                      </span>-->
<!--                                            </div>-->
<!--                                            <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">SSL Certificates</h3>-->
<!--                                            <p class="mt-5 text-base text-gray-500">-->
<!--                                                Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.-->
<!--                                            </p>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->

<!--                                <div class="pt-6">-->
<!--                                    <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">-->
<!--                                        <div class="-mt-6">-->
<!--                                            <div>-->
<!--                      <span class="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg">-->
<!--                        &lt;!&ndash; Heroicon name: outline/refresh &ndash;&gt;-->
<!--                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">-->
<!--                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />-->
<!--                        </svg>-->
<!--                      </span>-->
<!--                                            </div>-->
<!--                                            <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">Simple Queues</h3>-->
<!--                                            <p class="mt-5 text-base text-gray-500">-->
<!--                                                Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.-->
<!--                                            </p>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->

<!--                                <div class="pt-6">-->
<!--                                    <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">-->
<!--                                        <div class="-mt-6">-->
<!--                                            <div>-->
<!--                      <span class="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg">-->
<!--                        &lt;!&ndash; Heroicon name: outline/shield-check &ndash;&gt;-->
<!--                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">-->
<!--                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />-->
<!--                        </svg>-->
<!--                      </span>-->
<!--                                            </div>-->
<!--                                            <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">Advanced Security</h3>-->
<!--                                            <p class="mt-5 text-base text-gray-500">-->
<!--                                                Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.-->
<!--                                            </p>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->

<!--                                <div class="pt-6">-->
<!--                                    <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">-->
<!--                                        <div class="-mt-6">-->
<!--                                            <div>-->
<!--                      <span class="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg">-->
<!--                        &lt;!&ndash; Heroicon name: outline/cog &ndash;&gt;-->
<!--                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">-->
<!--                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />-->
<!--                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />-->
<!--                        </svg>-->
<!--                      </span>-->
<!--                                            </div>-->
<!--                                            <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">Powerful API</h3>-->
<!--                                            <p class="mt-5 text-base text-gray-500">-->
<!--                                                Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.-->
<!--                                            </p>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->

<!--                                <div class="pt-6">-->
<!--                                    <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">-->
<!--                                        <div class="-mt-6">-->
<!--                                            <div>-->
<!--                      <span class="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg">-->
<!--                        &lt;!&ndash; Heroicon name: outline/server &ndash;&gt;-->
<!--                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">-->
<!--                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />-->
<!--                        </svg>-->
<!--                      </span>-->
<!--                                            </div>-->
<!--                                            <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">Database Backups</h3>-->
<!--                                            <p class="mt-5 text-base text-gray-500">-->
<!--                                                Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.-->
<!--                                            </p>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->

                        </div>
                    </div>
                </div>

                <!-- Testimonial section -->
                <div class="rounded-3xl mt-10 mx-10 pb-16 bg-gradient-to-r from-jiruto-purpish to-jiruto-main lg:pb-0 lg:z-10 lg:relative">
                    <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
                        <div class="relative lg:-my-8">
                            <div aria-hidden="true" class="absolute inset-x-0 top-0 h-1/2 bg-jiruto-main lg:hidden"></div>
                            <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full ">
                                <div class="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                                    <img class="object-cover lg:h-full lg:w-full" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Fabout-us%2FjanuszKorczak.jpg?alt=media&token=f04a349d-c63e-494f-963a-e08e84687171" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
                            <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                                <blockquote>
                                    <div>
                                        <svg class="h-12 w-12 text-white opacity-25" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                                            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                        </svg>
                                        <p class="mt-1 text-2xl font-medium text-white">
                                            S/He who cares for days - sows wheat
                                        </p>
                                        <p class="mt-1 text-2xl font-medium text-white">
                                            S/He who cares for years - plants trees
                                        </p>
                                        <p class="mt-1 text-2xl font-medium text-white">
                                            S/He who cares for generations - <span class="text-jiruto-flatRed"> educates people</span>
                                        </p>
                                    </div>
                                    <footer class="mt-6">
                                        <p class="text-base font-medium text-white">Janusz Korczak</p>
                                        <p class="text-base font-medium text-teal-100">Jewish Polish Educator</p>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Feature section with screenshot -->
                <div class="rounded-3xl mt-10 mx-10 relative bg-jiruto-cotton py-16 sm:py-24 lg:py-32 overflow-hidden">
                    <div class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                        <div>
                            <h2 class="text-base font-semibold tracking-wider text-cyan-600 uppercase">so...</h2>
                            <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                                Here's The Plan
                            </p>
<!--                            <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">-->
<!--                                We give communities everything they need to manage themselves and help their members learn.-->
<!--                            </p>-->
<!--                            <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">-->
<!--                                We're looking for communities that wish to enter our first batch. We'll help them setup and migrate if needed and make sure their needs are met.-->
<!--                            </p>-->

<!--                            <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">-->
<!--                                In the meantime, more cool features and products are waiting to be built and published.-->
<!--                            </p>-->
                        </div>

                        <!-- component -->
                        <div class="container mx-auto w-full h-full mt-8">
                            <div class="relative wrap overflow-hidden h-full">
                                <div class="border-2-2 absolute border-opacity-20 border-gray-700 h-full border" style="left: 50%"></div>
                                <!-- right timeline -->
                                <div class="mb-8 flex justify-between items-center w-full right-timeline">
                                    <div class="order-1 w-5/12"></div>
                                    <div class="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
                                        <h1 class="mx-auto font-semibold text-lg text-white">1</h1>
                                    </div>
                                    <div class="order-1 bg-jiruto-prelude rounded-lg shadow-xl w-5/12 px-6 py-4">
                                        <h3 class="mb-3 font-bold text-gray-800 text-xl">Invite communities to our first batch</h3>
                                        <p class="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">We give communities everything they need to manage themselves and help their members learn. We're looking for communities that wish to enter our first batch. We'll help them setup and migrate if needed and make sure their needs are met.</p>
                                    </div>
                                </div>

                                <!-- left timeline -->
                                <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                                    <div class="order-1 w-5/12"></div>
                                    <div class="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
                                        <h1 class="mx-auto text-white font-semibold text-lg">2</h1>
                                    </div>
                                    <div class="order-1 bg-jiruto-deepBlue rounded-lg shadow-xl w-5/12 px-6 py-4">
                                        <h3 class="mb-3 font-bold text-white text-xl">Help them grow</h3>
                                        <p class="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100">Once the first batch is finalized, we'll focus on engagement and growth. The stronger the communities are the better Jiruto fulfills its function. The vision is for people to know that by being a part of Jiruto communities, they learn more. </p>
                                    </div>
                                </div>

                                <!-- right timeline -->
                                <div class="mb-8 flex justify-between items-center w-full right-timeline">
                                    <div class="order-1 w-5/12"></div>
                                    <div class="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
                                        <h1 class="mx-auto font-semibold text-lg text-white">3</h1>
                                    </div>
                                    <div class="order-1 bg-jiruto-prelude rounded-lg shadow-xl w-5/12 px-6 py-4">
                                        <h3 class="mb-3 font-bold text-gray-800 text-xl">More features</h3>
                                        <p class="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">There are 3 main types of tools we're going to build and insert, these are tools that: 1) Increase engagement 2) Help administrate 3) Show data and analytics.</p>
                                    </div>
                                </div>

                                <!-- left timeline -->
                                <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                                    <div class="order-1 w-5/12"></div>
                                    <div class="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
                                        <h1 class="mx-auto text-white font-semibold text-lg">4</h1>
                                    </div>
                                    <div class="order-1 bg-jiruto-deepBlue rounded-lg shadow-xl w-5/12 px-6 py-4">
                                        <h3 class="mb-3 font-bold text-white text-xl">Certificates!</h3>
                                        <p class="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100">After we establish strong and vibrant learning communities, we think it's fair that a community should be able to certificate its members. Together with the communities we will create a certification system.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-8">
                            <div class="inline-flex rounded-md shadow">
                                <a href="#" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
                                    I want my community in the first batch!
                                    <!-- Heroicon name: solid/external-link -->
                                    <!--                                    <svg class="-mr-1 ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
                                    <!--                                        <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />-->
                                    <!--                                        <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />-->
                                    <!--                                    </svg>-->
                                </a>
                            </div>
                        </div>
<!--                        <div class="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">-->
<!--                            <img class="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5" src="https://tailwindui.com/img/component-images/green-project-app-screenshot.jpg" alt="">-->
<!--                        </div>-->
                    </div>
                </div>







                <!-- Blog section -->
<!--                <div class="rounded-3xl mx-5 mt-5 relative bg-gray-50 py-16 sm:py-24 lg:py-32">-->
<!--                    <div class="relative">-->
<!--                        <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">-->
<!--                            <h2 class="text-base font-semibold tracking-wider text-cyan-600 uppercase">Learn</h2>-->
<!--                            <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">-->
<!--                                Helpful Resources-->
<!--                            </p>-->
<!--                            <p class="mt-5 mx-auto max-w-prose text-xl text-gray-500">-->
<!--                                Phasellus lorem quam molestie id quisque diam aenean nulla in. Accumsan in quis quis nunc, ullamcorper malesuada. Eleifend condimentum id viverra nulla.-->
<!--                            </p>-->
<!--                        </div>-->
<!--                        <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">-->
<!--                            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">-->
<!--                                <div class="flex-shrink-0">-->
<!--                                    <img class="h-48 w-full object-cover" src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixqx=dmqxMsuM0L&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80" alt="">-->
<!--                                </div>-->
<!--                                <div class="flex-1 bg-white p-6 flex flex-col justify-between">-->
<!--                                    <div class="flex-1">-->
<!--                                        <p class="text-sm font-medium text-cyan-600">-->
<!--                                            <a href="#" class="hover:underline">-->
<!--                                                Article-->
<!--                                            </a>-->
<!--                                        </p>-->
<!--                                        <a href="#" class="block mt-2">-->
<!--                                            <p class="text-xl font-semibold text-gray-900">-->
<!--                                                Boost your conversion rate-->
<!--                                            </p>-->
<!--                                            <p class="mt-3 text-base text-gray-500">-->
<!--                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.-->
<!--                                            </p>-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                    <div class="mt-6 flex items-center">-->
<!--                                        <div class="flex-shrink-0">-->
<!--                                            <a href="#">-->
<!--                                                <span class="sr-only">Roel Aufderehar</span>-->
<!--                                                <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=dmqxMsuM0L&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">-->
<!--                                            </a>-->
<!--                                        </div>-->
<!--                                        <div class="ml-3">-->
<!--                                            <p class="text-sm font-medium text-gray-900">-->
<!--                                                <a href="#" class="hover:underline">-->
<!--                                                    Roel Aufderehar-->
<!--                                                </a>-->
<!--                                            </p>-->
<!--                                            <div class="flex space-x-1 text-sm text-gray-500">-->
<!--                                                <time datetime="2020-03-16">-->
<!--                                                    Mar 16, 2020-->
<!--                                                </time>-->
<!--                                                <span aria-hidden="true">-->
<!--                        &middot;-->
<!--                      </span>-->
<!--                                                <span>-->
<!--                        6 min read-->
<!--                      </span>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->

<!--                            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">-->
<!--                                <div class="flex-shrink-0">-->
<!--                                    <img class="h-48 w-full object-cover" src="https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixqx=dmqxMsuM0L&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80" alt="">-->
<!--                                </div>-->
<!--                                <div class="flex-1 bg-white p-6 flex flex-col justify-between">-->
<!--                                    <div class="flex-1">-->
<!--                                        <p class="text-sm font-medium text-cyan-600">-->
<!--                                            <a href="#" class="hover:underline">-->
<!--                                                Video-->
<!--                                            </a>-->
<!--                                        </p>-->
<!--                                        <a href="#" class="block mt-2">-->
<!--                                            <p class="text-xl font-semibold text-gray-900">-->
<!--                                                How to use search engine optimization to drive sales-->
<!--                                            </p>-->
<!--                                            <p class="mt-3 text-base text-gray-500">-->
<!--                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.-->
<!--                                            </p>-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                    <div class="mt-6 flex items-center">-->
<!--                                        <div class="flex-shrink-0">-->
<!--                                            <a href="#">-->
<!--                                                <span class="sr-only">Brenna Goyette</span>-->
<!--                                                <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixqx=dmqxMsuM0L&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">-->
<!--                                            </a>-->
<!--                                        </div>-->
<!--                                        <div class="ml-3">-->
<!--                                            <p class="text-sm font-medium text-gray-900">-->
<!--                                                <a href="#" class="hover:underline">-->
<!--                                                    Brenna Goyette-->
<!--                                                </a>-->
<!--                                            </p>-->
<!--                                            <div class="flex space-x-1 text-sm text-gray-500">-->
<!--                                                <time datetime="2020-03-10">-->
<!--                                                    Mar 10, 2020-->
<!--                                                </time>-->
<!--                                                <span aria-hidden="true">-->
<!--                        &middot;-->
<!--                      </span>-->
<!--                                                <span>-->
<!--                        4 min read-->
<!--                      </span>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->

<!--                            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">-->
<!--                                <div class="flex-shrink-0">-->
<!--                                    <img class="h-48 w-full object-cover" src="https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixqx=dmqxMsuM0L&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80" alt="">-->
<!--                                </div>-->
<!--                                <div class="flex-1 bg-white p-6 flex flex-col justify-between">-->
<!--                                    <div class="flex-1">-->
<!--                                        <p class="text-sm font-medium text-cyan-600">-->
<!--                                            <a href="#" class="hover:underline">-->
<!--                                                Case Study-->
<!--                                            </a>-->
<!--                                        </p>-->
<!--                                        <a href="#" class="block mt-2">-->
<!--                                            <p class="text-xl font-semibold text-gray-900">-->
<!--                                                Improve your customer experience-->
<!--                                            </p>-->
<!--                                            <p class="mt-3 text-base text-gray-500">-->
<!--                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.-->
<!--                                            </p>-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                    <div class="mt-6 flex items-center">-->
<!--                                        <div class="flex-shrink-0">-->
<!--                                            <a href="#">-->
<!--                                                <span class="sr-only">Daniela Metz</span>-->
<!--                                                <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixqx=dmqxMsuM0L&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">-->
<!--                                            </a>-->
<!--                                        </div>-->
<!--                                        <div class="ml-3">-->
<!--                                            <p class="text-sm font-medium text-gray-900">-->
<!--                                                <a href="#" class="hover:underline">-->
<!--                                                    Daniela Metz-->
<!--                                                </a>-->
<!--                                            </p>-->
<!--                                            <div class="flex space-x-1 text-sm text-gray-500">-->
<!--                                                <time datetime="2020-02-12">-->
<!--                                                    Feb 12, 2020-->
<!--                                                </time>-->
<!--                                                <span aria-hidden="true">-->
<!--                        &middot;-->
<!--                      </span>-->
<!--                                                <span>-->
<!--                        11 min read-->
<!--                      </span>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->





                <!-- CTA Section -->
                <div class="rounded-3xl mt-10 mx-10 relative bg-gray-900 overflow-hidden" >
                    <div class="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2 overflow-hidden">
                        <img class="w-full h-full object-cover" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Fabout-us%2Fcoffee.jpg?alt=media&token=0890ce6c-d2d4-4034-b99f-58b8235cc356" alt="">
<!--                        <div aria-hidden="true" class="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600" style="mix-blend-mode: multiply;"></div>-->
                    </div>
                    <div class="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
                        <div class="md:ml-auto md:w-1/2 md:pl-10">
                            <h2 class="text-base font-semibold uppercase tracking-wider text-gray-300">
                                Is education your thing?
                            </h2>
                            <p class="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
                                Support the cause!
                            </p>
                            <p class="mt-3 text-lg text-gray-300">
                                 I'm a true believer, and as such I'm working on Jiruto full time. Probably even two full times.
                            </p>
                            <p class="mt-3 text-lg text-gray-300">
                                However, building and maintaining Jiruto on my own is a lot of work and resources, and with newborn twins there's little time left to sleep. Therefore..
                            </p>
                            <div class="mt-8">
                                <div class="inline-flex rounded-md shadow">
                                    <a href="#" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
                                        Buy me a coffee. I need a coffee.
                                        <!-- Heroicon name: solid/external-link -->
                                        <svg class="-mr-1 ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                            <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

<!--            <footer class="rounded-3xl mx-5 mt-5 bg-gray-50" aria-labelledby="footerHeading">-->
<!--                <h2 id="footerHeading" class="sr-only">Footer</h2>-->
<!--                <div class="max-w-md mx-auto pt-12 px-4 sm:max-w-7xl sm:px-6 lg:pt-16 lg:px-8">-->
<!--                    <div class="xl:grid xl:grid-cols-3 xl:gap-8">-->
<!--                        <div class="space-y-8 xl:col-span-1">-->
<!--                            <img class="h-10" src="https://tailwindui.com/img/logos/workflow-mark-gray-300.svg" alt="Company name">-->
<!--                            <p class="text-gray-500 text-base">-->
<!--                                Making the world a better place through constructing elegant hierarchies.-->
<!--                            </p>-->
<!--                            <div class="flex space-x-6">-->
<!--                                <a href="#" class="text-gray-400 hover:text-gray-500">-->
<!--                                    <span class="sr-only">Facebook</span>-->
<!--                                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">-->
<!--                                        <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />-->
<!--                                    </svg>-->
<!--                                </a>-->

<!--                                <a href="#" class="text-gray-400 hover:text-gray-500">-->
<!--                                    <span class="sr-only">Instagram</span>-->
<!--                                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">-->
<!--                                        <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />-->
<!--                                    </svg>-->
<!--                                </a>-->

<!--                                <a href="#" class="text-gray-400 hover:text-gray-500">-->
<!--                                    <span class="sr-only">Twitter</span>-->
<!--                                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">-->
<!--                                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />-->
<!--                                    </svg>-->
<!--                                </a>-->

<!--                                <a href="#" class="text-gray-400 hover:text-gray-500">-->
<!--                                    <span class="sr-only">GitHub</span>-->
<!--                                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">-->
<!--                                        <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" />-->
<!--                                    </svg>-->
<!--                                </a>-->

<!--                                <a href="#" class="text-gray-400 hover:text-gray-500">-->
<!--                                    <span class="sr-only">Dribbble</span>-->
<!--                                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">-->
<!--                                        <path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" />-->
<!--                                    </svg>-->
<!--                                </a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">-->
<!--                            <div class="md:grid md:grid-cols-2 md:gap-8">-->
<!--                                <div>-->
<!--                                    <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">-->
<!--                                        Solutions-->
<!--                                    </h3>-->
<!--                                    <ul class="mt-4 space-y-4">-->
<!--                                        <li>-->
<!--                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--                                                Marketing-->
<!--                                            </a>-->
<!--                                        </li>-->

<!--                                        <li>-->
<!--                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--                                                Analytics-->
<!--                                            </a>-->
<!--                                        </li>-->

<!--                                        <li>-->
<!--                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--                                                Commerce-->
<!--                                            </a>-->
<!--                                        </li>-->

<!--                                        <li>-->
<!--                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--                                                Insights-->
<!--                                            </a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--                                <div class="mt-12 md:mt-0">-->
<!--                                    <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">-->
<!--                                        Support-->
<!--                                    </h3>-->
<!--                                    <ul class="mt-4 space-y-4">-->
<!--                                        <li>-->
<!--                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--                                                Pricing-->
<!--                                            </a>-->
<!--                                        </li>-->

<!--                                        <li>-->
<!--                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--                                                Documentation-->
<!--                                            </a>-->
<!--                                        </li>-->

<!--                                        <li>-->
<!--                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--                                                Guides-->
<!--                                            </a>-->
<!--                                        </li>-->

<!--                                        <li>-->
<!--                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--                                                API Status-->
<!--                                            </a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="md:grid md:grid-cols-2 md:gap-8">-->
<!--                                <div>-->
<!--                                    <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">-->
<!--                                        Company-->
<!--                                    </h3>-->
<!--                                    <ul class="mt-4 space-y-4">-->
<!--                                        <li>-->
<!--                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--                                                About-->
<!--                                            </a>-->
<!--                                        </li>-->

<!--                                        <li>-->
<!--                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--                                                Blog-->
<!--                                            </a>-->
<!--                                        </li>-->

<!--                                        <li>-->
<!--                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--                                                Jobs-->
<!--                                            </a>-->
<!--                                        </li>-->

<!--                                        <li>-->
<!--                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--                                                Press-->
<!--                                            </a>-->
<!--                                        </li>-->

<!--                                        <li>-->
<!--                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--                                                Partners-->
<!--                                            </a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--//                                <div class="mt-12 md:mt-0">-->
<!--//                                    <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">-->
<!--//                                        Legal-->
<!--//                                    </h3>-->
<!--//                                    <ul class="mt-4 space-y-4">-->
<!--//                                        <li>-->
<!--//                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--//                                                Claim-->
<!--//                                            </a>-->
<!--//                                        </li>-->
<!--//-->
<!--//                                        <li>-->
<!--//                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--//                                                Privacy-->
<!--//                                            </a>-->
<!--//                                        </li>-->
<!--//-->
<!--//                                        <li>-->
<!--//                                            <a href="#" class="text-base text-gray-500 hover:text-gray-900">-->
<!--//                                                Terms-->
<!--//                                            </a>-->
<!--//                                        </li>-->
<!--//                                    </ul>-->
<!--//                                </div>-->
<!--//                            </div>-->
<!--//                        </div>-->
<!--                    </div>-->
<!--                    <div class="mt-12 border-t border-gray-200 py-8">-->
<!--                        <p class="text-base text-gray-400 xl:text-center">-->
<!--                            &copy; 2020 Workflow, Inc. All rights reserved.-->
<!--                        </p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </footer>-->
        </div>
    </div>


</template>

<script>
    export default {
        name: "NewAboutUs"
    }
</script>

<style scoped>

</style>
